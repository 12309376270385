import React, { useState, useEffect } from 'react';
import '../Designe/WeldingMachine.css';
import Miller from '../img/WeldingMachiones/Miller_front.png';
import Miller_back from '../img/WeldingMachiones/Miller_back.jpg';
import iWeld from '../img/WeldingMachiones/iWeld_front.png';
import iWeld_Back from '../img/WeldingMachiones/iWeld_back.jpg';
import Migatronic from '../img/WeldingMachiones/Miga_front.png';
import Migatronic_back from '../img/WeldingMachiones/Miga_back.jpg';
import Lincoln from '../img/WeldingMachiones/Lincoln_front.png';
import Lincoln_back from '../img/WeldingMachiones/Lincoln_back.jpg';
import Lorch from '../img/WeldingMachiones/Lorch_front.png';
import Lorch_back from '../img/WeldingMachiones/Lorch_Back.jpg';
import Kemppi from '../img/WeldingMachiones/Kempi_front.png';
import Kemppi_back from '../img/WeldingMachiones/Kemppi_back.jpg';
import Merkle from '../img/WeldingMachiones/Merkle_front.png';
import Merkle_back from '../img/WeldingMachiones/Merkle_back.jpg';
import CEA from '../img/WeldingMachiones/CEA.png';
import CEA_back from '../img/WeldingMachiones/CEA_Back.jpg';
import EWM from '../img/WeldingMachiones/EWM.png';
import EWM_back from '../img/WeldingMachiones/EWM_back.JPG';
import WECO from '../img/WeldingMachiones/WECO.png';
import WECO_back from '../img/WeldingMachiones/Weco_back.jpg';
import Syrius from '../img/WeldingMachiones/Syrius.png';
import Syrius_back from '../img/WeldingMachiones/syrius_back.png';
import REHM from '../img/WeldingMachiones/Rehm.png';
import REHM_back from '../img/WeldingMachiones/Rehm_back.jpg';

import next from '../img/next.png'
import back from '../img/back.png'

import { useSelector, useDispatch } from 'react-redux';
import translations from '../strings/Strings'




const WeldingMachines = () => {

    const language = useSelector((state) => state.language);
    const [strings,setStrings] = useState(translations.hun)

    const iterations = [
        [
            { frontImg: Miller, backImg: Miller_back, frontText: 'Miller', backText: 'Miller', link: 'https://youtu.be/GxpV2BYkX_0' },
            { frontImg: iWeld, backImg: iWeld_Back, frontText: 'iWeld', backText: 'iWeld', link: 'https://youtu.be/8qBgwag-6LI' },
            { frontImg: Migatronic, backImg: Migatronic_back, frontText: 'Migatronic', backText: 'Migatronic', link: 'https://youtu.be/X1rcMBaggoc' },
            { frontImg: Lincoln, backImg: Lincoln_back, frontText: 'Lincoln', backText: 'Lincoln', link: 'https://youtu.be/yHiQm6Wt4M8' },
            { frontImg: Lorch, backImg: Lorch_back, frontText: 'Lorch', backText: 'Lorch', link: 'https://youtu.be/zirN5HEGe-g' },
            { frontImg: Kemppi, backImg: Kemppi_back, frontText: 'Kemppi', backText: 'Kemppi', link: 'https://youtu.be/7vB-pEu9ZzQ' }
        ],
       [
            { frontImg: EWM, backImg: EWM_back, frontText: 'EWM', backText: 'EWM', link: 'https://youtube.com/shorts/0G43pjKxNx4'  },
            { frontImg: WECO, backImg: WECO_back, frontText: 'Weco', backText: 'Weco', link: 'https://youtube.com/shorts/5yKKs45q6Wo' },
            { frontImg: CEA, backImg: CEA_back, frontText: 'CEA', backText: 'CEA', link: 'https://youtube.com/shorts/0G43pjKxNx4' },
            { frontImg: REHM, backImg: REHM_back, frontText: 'Rehm', backText: 'Rehm', link: 'https://youtu.be/Suu3dNWI7Nk' },
            { frontImg: Merkle, backImg: Merkle_back, frontText: 'Merkle', backText: 'Merkle', link: 'https://youtu.be/WAUA-Z7V1X0' },
            { frontImg: Syrius, backImg: Syrius_back, frontText: 'Syrius', backText: 'Syrius', link: 'https://www.youtube.com/watch?v=E5Z46YLklmo&t=23s' }
        ],
    ];

    // Kezdeti állapot és iterációs index
    const [iterationIndex, setIterationIndex] = useState(0);
    const [machines, setMachines] = useState(iterations[0]);
    const [Image, setImage] = useState(iterations[0][0].frontImg);
    const [Title, setTitle] = useState(iterations[0][0].frontText);
    const [Link, setLink] = useState(iterations[0][0].link);
    const [Image_back, setImage_back] = useState(iterations[0][0].backImg);
    const [Index, setIndex] = useState(0);
    const [Arrey_Index, setArrey_Index] = useState(0);

    // Képek és szövegek cseréje
    const handleLoadMore = () => {
        const nextIndex = (iterationIndex + 1) % iterations.length; // Körkörös iteráció
        setIterationIndex(nextIndex);
        setMachines(iterations[nextIndex]);
    };

    // Kártyára kattintás kezelése
    const handleCardClick = (link) => {
        window.open(link, '_blank'); // Új lapon nyitja meg a YouTube linket
    };
    useEffect(() => {
        // Itt lehet kezelni a nyelv állapotváltozásait
        console.log('Nyelv megváltozott:', language);
        if(language=== 'en'){
          setStrings(translations.en)
        }else{
          setStrings(translations.hun)
        }
      }, [language]);


       // Képek és szövegek cseréje
    const increaseWelders = () => {
        const nextIndex = (Index + 1) % iterations[0].length; // Körkörös iteráció
        if(nextIndex==5 && Arrey_Index==0){
            setArrey_Index(1)
        }

        if(nextIndex==5 && Arrey_Index==1){
            setArrey_Index(0)
        }

        setIndex(nextIndex);
        setImage(iterations[Arrey_Index][nextIndex].frontImg);
        setTitle(iterations[Arrey_Index][nextIndex].frontText);
        setImage_back(iterations[Arrey_Index][nextIndex].backImg);
        setLink(iterations[Arrey_Index][nextIndex].link)
    };

           // Képek és szövegek cseréje
           const decreaseWelders = () => {
            var nextIndex = (Index - 1); // Körkörös iteráció
           if(nextIndex<0 && Arrey_Index==0){
                setArrey_Index(1)
                nextIndex=5
            }
    
            if(nextIndex<0 && Arrey_Index==1){
                setArrey_Index(0)
                nextIndex=5 
            }
    
            setIndex(nextIndex);
            setImage(iterations[Arrey_Index][Index].frontImg);
            setTitle(iterations[Arrey_Index][Index].frontText);
            setImage_back(iterations[Arrey_Index][Index].backImg);
            setLink(iterations[Arrey_Index][Index].link)
            console.log("data")
        };
    
    if(window.innerWidth>762){
    return (
        <div className="page-container_" id='AWI Hegesztőgépek'>
            <h1>{strings.WeldingMachines_Title}</h1>
            <div className="cards-container_">
                {machines.map((machine, index) => (
                    <div className="card_" key={index} onClick={() => handleCardClick(machine.link)}>
                        <div className="card-inner_">
                            <div className="card-front_">
                                <img src={machine.frontImg} alt={`${machine.frontText} hegesztőgép előlnézet`} />
                                <p>{machine.frontText}</p>
                            </div>
                            <div className="card-back_">
                                <img src={machine.backImg} alt={`${machine.backText} hegesztőgép előlnézet`} />
                                <p>{machine.backText}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button className="load-more-btn" onClick={handleLoadMore}>{strings.WeldingMachines_Button}</button>
        </div>
    );
}else{
    return(
        <div class="split-screen" id='AWI Hegesztőgépek'>
          <div class="top-pane">
            <div className='top-pane-title'>
            <h2>{strings.WeldingMachines_mobile_Title}</h2>
            </div>
            <div className='top-pane-img'>
            <img src={Image_back} style={{height: 200}}/>
              </div>
          </div>
          <div class="bottom-pane">
            <div className='bottom-pane-arrow'>
              <img src={back} onClick={decreaseWelders} />
            </div>
            <div className='bottom-pane-middle'>
            <div className="card">
            <h3 style={{fontSize: window.innerHeight/40}} className="card-title">{Title}</h3>
                  <div className="card-content">
                    <img src={Image} className="card-content-image"/>
                    <button onClick={() => handleCardClick(Link)}  className="card-button">{strings.WeldingMachines_mobile_Button}</button>
                  </div>
                </div>
            </div>
            <div className='bottom-pane-arrow'>
            <img src={next} onClick={increaseWelders} />
            </div>
          </div>
        </div>
        )
    
}
}



export default WeldingMachines;
