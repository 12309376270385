import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostJobs.css';
import hegesztobacsi from '../../img/coffee_cup.jpg';
import iv from '../../img/en.jpg';
import { useSelector, useDispatch } from 'react-redux';

import translations from '../../strings/Strings'

function BlogPostJobs() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);



  var url = 'https://orbitalchamp.com/EzertLeszelEgyreErtekesebbMintHegeszto';
  var title = 'Ezért leszel egyre értékesebb, mint hegesztő!';
  var description = 'Az utóbbi években a hegesztők iránti kereslet jelentősen megnövekedett, és ez a tendencia várhatóan folytatódni fog a jövőben is. De miért is leszel egyre értékesebb mint hegesztő, és hogyan használhatod ki a szakmában rejlő lehetőségeket? Ebben a bejegyzésben megvizsgáljuk azokat a tényezőket, amelyek hozzájárulnak ahhoz, hogy hegesztőként egyre nagyobb megbecsülésben részesülj.';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]);  
  







  if(isMobile==false){
  return (
    <div className="blog-container">
      <div className="menu">
        <ul>
          <li><a href="/shop" replace>Bolt</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a className="a_active" href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a  href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>


        </ul>
      </div>

      <div className="blog-content">
        <h1 className="bela">Ezért leszel egyre értékesebb, mint hegesztő</h1>
        <img className="welding-image_job" src={hegesztobacsi} alt="Orbitális hegesztő munka közben" />
        <p>
          Az utóbbi években a hegesztők iránti kereslet jelentősen megnövekedett, és ez a tendencia várhatóan folytatódni fog a jövőben is. De miért is leszel egyre értékesebb mint hegesztő, és hogyan használhatod ki a szakmában rejlő lehetőségeket? Ebben a bejegyzésben megvizsgáljuk azokat a tényezőket, amelyek hozzájárulnak ahhoz, hogy hegesztőként egyre nagyobb megbecsülésben részesülj.
        </p>

        <ul>
          <li>
            <strong>Növekvő munkaerőhiány:</strong>
            <p>
              A hegesztőipar egyik legnagyobb kihívása jelenleg a szakemberhiány. Sok tapasztalt hegesztő közeledik a nyugdíjhoz, miközben az utánpótlás nem tud lépést tartani a kieső munkaerővel. Az Európai Unióban a hegesztők iránti kereslet folyamatosan növekszik, és a European Employment Service jelentése szerint a hegesztő szakemberek hiánya az egyik legmagasabb az EU-ban. Ez a hiány növeli a meglévő hegesztők értékét a munkaerőpiacon.
            </p>
          </li>
          <li>
            <strong>Infrastruktúra fejlesztések és ipari növekedés:</strong>
            <p>
              A globális infrastruktúra-fejlesztési projektek száma növekszik, legyen szó építőiparról, energetikai beruházásokról vagy közlekedési hálózatokról. Ezek a projektek mind hegesztőket igényelnek, hiszen nélkülük nem valósíthatók meg a szükséges szerkezetek és rendszerek. Az ipari növekedés további munkalehetőségeket teremt a hegesztők számára, és növeli a szakma értékét.
            </p>
          </li>
          <li>
            <strong>Technológiai fejlődés és specializáció:</strong>
            <p>
              Az új technológiák megjelenése, mint például az orbitális hegesztés, lézerhegesztés és más fejlett hegesztési technikák, olyan szakembereket igényelnek, akik képesek alkalmazkodni és elsajátítani ezeket az innovációkat. Azok a hegesztők, akik lépést tartanak a technológiai fejlődéssel, különösen értékesek lesznek a munkaerőpiacon.
            </p>
          </li>
          <li>
            <strong>Automatizáció és emberi szakértelem:</strong>
            <p>
              Bár az automatizáció egyre nagyobb szerepet kap az iparban, a hegesztők szakértelme továbbra is nélkülözhetetlen. Az automatizált rendszerek felügyelete, beállítása és karbantartása olyan feladatok, amelyekhez tapasztalt hegesztőkre van szükség. Az emberi kreativitás és problémamegoldó képesség pótolhatatlan, különösen összetett projektek esetén.
            </p>
          </li>
          <li>
            <strong>Versenyképes bérezés és karrierlehetőségek:</strong>
            <p>
              A növekvő kereslet és a szakemberhiány miatt a hegesztők bérezése is emelkedik. Emellett számos lehetőség nyílik a szakmai előrelépésre, legyen szó specializációról vagy vezetői pozíciók betöltéséről. A folyamatos tanulás és fejlődés lehetővé teszi, hogy még értékesebbé válj a munkaadók szemében.
            </p>
          </li>
        </ul>

        <h2>Összegzés</h2>
        <p>
          A hegesztő szakma előtt fényes jövő áll. A munkaerőhiány, az ipari növekedés és a technológiai fejlődés mind hozzájárulnak ahhoz, hogy hegesztőként egyre értékesebb legyél. Érdemes kihasználni ezeket a lehetőségeket, folyamatosan fejleszteni tudásodat és alkalmazkodni az új technológiákhoz. Így nemcsak biztosíthatod a helyed a munkaerőpiacon, hanem jelentős mértékben hozzájárulhatsz az ipar fejlődéséhez is.
        </p>

        <div className='author_div'>
          <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
          <div className='author-text'>
            <p>
              <strong>Horváth Benedegúz</strong> <br />
              <hr className="rounded"></hr>
              <span className="author-title">OrbitChamp CEO</span>
            </p>
          </div>
        </div>

        
      </div>

      <div className='empty-space'></div>
    </div>
  );
}else{
  return (
    <div className="blog-container">
      <div className="blog-content">
        <h1 className="bela">Ezért leszel egyre értékesebb, mint hegesztő</h1>
        <img className="welding-image_job" src={hegesztobacsi} alt="Orbitális hegesztő munka közben" />
        <p>
          Az utóbbi években a hegesztők iránti kereslet jelentősen megnövekedett, és ez a tendencia várhatóan folytatódni fog a jövőben is. De miért is leszel egyre értékesebb mint hegesztő, és hogyan használhatod ki a szakmában rejlő lehetőségeket? Ebben a bejegyzésben megvizsgáljuk azokat a tényezőket, amelyek hozzájárulnak ahhoz, hogy hegesztőként egyre nagyobb megbecsülésben részesülj.
        </p>

        <ul>
          <li>
            <strong>Növekvő munkaerőhiány:</strong>
            <p>
              A hegesztőipar egyik legnagyobb kihívása jelenleg a szakemberhiány. Sok tapasztalt hegesztő közeledik a nyugdíjhoz, miközben az utánpótlás nem tud lépést tartani a kieső munkaerővel. Az Európai Unióban a hegesztők iránti kereslet folyamatosan növekszik, és a European Employment Service jelentése szerint a hegesztő szakemberek hiánya az egyik legmagasabb az EU-ban. Ez a hiány növeli a meglévő hegesztők értékét a munkaerőpiacon.
            </p>
          </li>
          <li>
            <strong>Infrastruktúra fejlesztések és ipari növekedés:</strong>
            <p>
              A globális infrastruktúra-fejlesztési projektek száma növekszik, legyen szó építőiparról, energetikai beruházásokról vagy közlekedési hálózatokról. Ezek a projektek mind hegesztőket igényelnek, hiszen nélkülük nem valósíthatók meg a szükséges szerkezetek és rendszerek. Az ipari növekedés további munkalehetőségeket teremt a hegesztők számára, és növeli a szakma értékét.
            </p>
          </li>
          <li>
            <strong>Technológiai fejlődés és specializáció:</strong>
            <p>
              Az új technológiák megjelenése, mint például az orbitális hegesztés, lézerhegesztés és más fejlett hegesztési technikák, olyan szakembereket igényelnek, akik képesek alkalmazkodni és elsajátítani ezeket az innovációkat. Azok a hegesztők, akik lépést tartanak a technológiai fejlődéssel, különösen értékesek lesznek a munkaerőpiacon.
            </p>
          </li>
          <li>
            <strong>Automatizáció és emberi szakértelem:</strong>
            <p>
              Bár az automatizáció egyre nagyobb szerepet kap az iparban, a hegesztők szakértelme továbbra is nélkülözhetetlen. Az automatizált rendszerek felügyelete, beállítása és karbantartása olyan feladatok, amelyekhez tapasztalt hegesztőkre van szükség. Az emberi kreativitás és problémamegoldó képesség pótolhatatlan, különösen összetett projektek esetén.
            </p>
          </li>
          <li>
            <strong>Versenyképes bérezés és karrierlehetőségek:</strong>
            <p>
              A növekvő kereslet és a szakemberhiány miatt a hegesztők bérezése is emelkedik. Emellett számos lehetőség nyílik a szakmai előrelépésre, legyen szó specializációról vagy vezetői pozíciók betöltéséről. A folyamatos tanulás és fejlődés lehetővé teszi, hogy még értékesebbé válj a munkaadók szemében.
            </p>
          </li>
        </ul>

        <h2>Összegzés</h2>
        <p>
          A hegesztő szakma előtt fényes jövő áll. A munkaerőhiány, az ipari növekedés és a technológiai fejlődés mind hozzájárulnak ahhoz, hogy hegesztőként egyre értékesebb legyél. Érdemes kihasználni ezeket a lehetőségeket, folyamatosan fejleszteni tudásodat és alkalmazkodni az új technológiákhoz. Így nemcsak biztosíthatod a helyed a munkaerőpiacon, hanem jelentős mértékben hozzájárulhatsz az ipar fejlődéséhez is.
        </p>

        <div className='author_div'>
          <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
          <div className='author-text'>
            <p>
              <strong>Horváth Benedegúz</strong> <br />
              <hr className="rounded"></hr>
              <span className="author-title">OrbitChamp CEO</span>
            </p>
          </div>
        </div>
        <div className="menu">
        <ul>
          <li><a href="/shop" replace>Bolt</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a className="a_active" href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a  href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>
        </ul>
      </div>
        
      </div>

    </div>
  );
}
}

export default BlogPostJobs;
