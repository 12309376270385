import React, { useState, useEffect } from 'react';
import '../Designe/OrbitalWelding.css';

import Orbital from '../img/form2.jpg'
import Orbital2 from '../img/Form.jpg'
import O1 from '../img/OrbitalWelding/O1.jpg'
import O2 from '../img/OrbitalWelding/O2.jpg'
import O3 from '../img/OrbitalWelding/O3.jpg'
import O4 from '../img/OrbitalWelding/O4.jpg'
import O5 from '../img/OrbitalWelding/O5.jpg'

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

const WeldingSection = () => {
    const images = [
        O3,
        O4,
        O5,
        Orbital,
        Orbital2,
        
        // További képek hozzáadása
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                (prevIndex + 1) % images.length
            );
        }, 3000); // 3 másodpercenként váltja a képeket

        return () => clearInterval(intervalId);
    }, [images.length]);

    const language = useSelector((state) => state.language);
    const [strings,setStrings] = useState(translations.hun)
    useEffect(() => {
      // Itt lehet kezelni a nyelv állapotváltozásait
      console.log('Nyelv megváltozott:', language);
      if(language=== 'en'){
        setStrings(translations.en)
      }else{
        setStrings(translations.hun)
      }
    }, [language]);

    return (
        <div className="welding-section" id='Orbitális Hegesztés'>
            <div className="welding-text">
                <h2>{strings.OrbitalWeldings_Title}</h2>
                <p className="description">
                    {strings.OrbitalWeldings_P1}
                </p>
                <p className="description">
                   {strings.OrbitalWeldings_P2}
                </p>
                <p className="description">
                    {strings.OrbitalWeldings_P3}
                </p>
            </div>
            <div className="welding-image">
                <img src={images[currentImageIndex]} alt="Orbitális hegesztés" />
            </div>
        </div>
    );
}

export default WeldingSection;
