import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Designe/Navbar.css';
import Logo from '../img/Logo.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';

import translations from '../strings/Strings'



const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [strings,setStrings] = useState(translations.hun)

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const EN =() => {
    dispatch(setLanguage('en'))
    setStrings(translations.en)
  } 

  const HUN =() => {
    dispatch(setLanguage('hun'))
    setStrings(translations.hun)
  } 

  useEffect(() => {

    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    

    // Frissítjük a windowWidth állapotot, amikor az ablak mérete megváltozik
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Töröljük az eseménykezelőt a komponens megszűnésekor
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
       <div className='menu-conatiner_'>

      <ul className="navbar-menu">
      <div className="navbar-logo">
        <img src={Logo} style={{height: window.innerHeight/8}} onClick={() => scrollToSection('home')} alt="OrbitalChamp" />
      </div>
        <Link to="/Orbitális hegesztő" onClick={() => scrollToSection('Orbitális hegesztő')} className="nav-link">
          <li className="navbar-item">
            <a href="#Orbitális hegesztő" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_Product_title}
            </a>
          </li>
        </Link>
        <Link to="/AWI Hegesztőgépek" onClick={() => scrollToSection('AWI Hegesztőgépek')} className="nav-link">
          <li className="navbar-item">
            <a href="/AWI Hegesztőgépek" className="navbar-link" style={{fontSize: windowWidth/70}}>
            {strings.Navbar_Application_title}
            </a>
          </li>
        </Link>
        <Link to="/Csőhegesztés" onClick={() => scrollToSection('Csőhegesztés')}className="nav-link">
          <li className="navbar-item">
            <a href="/Csőhegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_Weldings_title}
            </a>
          </li>
        </Link>
        <Link to="/Orbitális Hegesztés" onClick={() => scrollToSection('Orbitális Hegesztés')}className="nav-link">
          <li className="navbar-item">
            <a href="/Orbitális Hegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_OrbitalWelding_title}
            </a>
          </li>
        </Link>
        <li className="navbar-item">
          <button className='navbar-button' onClick={() => scrollToSection('contact')} style={{fontSize: windowWidth/60, padding: windowWidth/120}}>{strings.Navbar_Contact_title}</button>
        </li>
        <li className="navbar-item">
        <button className='navbar-button-lang' onClick={() => EN()}>EN</button>
        <button className='navbar-button-lang' onClick={() => HUN()}>HUN</button>
        </li>
        </ul>
       
    {/* Hamburger menü */}
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      {/* Itt vannak a menüpontok */}
      <a className="menu-item" onClick={() => (scrollToSection('Orbitális hegesztő'))}>
      {strings.Navbar_Product_title}
      </a>
      <a className="menu-item" onClick={() => (scrollToSection('AWI Hegesztőgépek'))}>
      {strings.Navbar_Application_title}
      </a>
      <a className="menu-item" onClick={() => (scrollToSection('Csőhegesztés'))}>
      {strings.Navbar_Weldings_title}
      </a>
      <a className="menu-item" onClick={() => (scrollToSection('Orbitális Hegesztés'))}>
      {strings.Navbar_OrbitalWelding_title}
      </a>
      <a className="menu-item"  onClick={() => (scrollToSection('contact'))}>
        {strings.Navbar_Contact_title}
      </a>
      <a className="menu-item"  href="/shop">Bolt</a>
      <a className="menu-item"  href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Blog</a>
    </Menu>
  </div>
    </nav>
    
    
  ); 

  return (
    
    <div className='menu-conatiner'>
    {/* Hamburger menü */}
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      {/* Itt vannak a menüpontok */}
      <a className="menu-item" onClick={() => (handleMenuItemClick('about'))}>
        {strings.Navbar_Product_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('AWI Hegesztőgépek'))}>
      {strings.Navbar_Application_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('Csőhegesztés'))}>
      {strings.Navbar_Weldings_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('Orbitális Hegesztés'))}>
      {strings.Navbar_OrbitalWelding_title}
      </a>
      <a className="menu-item"  onClick={() => (handleMenuItemClick('contact'))}>
        {strings.Navbar_Contact_title}
      </a>
    </Menu>
  </div>
  );
};

export default Navbar;
