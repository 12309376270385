


const translations = {
  hun: {
      Navbar_Product_title: 'Termékünk',
      Navbar_Application_title: 'Hegesztőgépek',
      Navbar_Weldings_title: 'Hegesztéseink',
      Navbar_OrbitalWelding_title: 'Orbitális hegesztés',
      Navbar_Contact_title: 'Kapcsolat',
      
      Welcome_Button: 'Lépj velünk kapcsolatba!',

      About_Head_title: 'OrbitalChamp',
      About_Head_desc: 'Az OrbitalChamp egy olyan hegesztő okosító berendezés, mellyel röntgen-biztos, tökéletes pontosságú és minőségű hegesztést tud végezni egy olyan munkavállaló, aki nem rendelkezik hegesztői végzettséggel',
      About_High_Quality_Weldings_title: 'Minősített hegesztés',
      About_High_Quality_Weldings_desc: 'Az OrbitalChamp-pel magas minőségű hegesztéseket lehet végezni. Erre bizonyíték, hogy rendelkezünk TÜV minősítéssel.',
      About_Track_Setting_title: 'Pályaállítás',
      About_Track_Setting_desc: 'Van lehetőségünk a hegesztési folyamat közben, a hegesztés pályát megváltoztatni',
      About_Electronic_title: 'Vezérlő elektronika',
      About_Electronic_desc: 'Magát a hegesztési folyamatot egy vezérlő elektronika vezérli le. Ezt az elektronikát egy mobil applikációval tudjuk programozni.',
      About_UI_title: 'Távirányító',
      About_UI_desc: 'Az OrbitalChamp egy távirányítóval vezérelhető eszköz, amelyen három darab gomb található, valamint egy kapcsoló és három LED. A nyomógombok segítségével pozícionálni lehet a fejet, illetve el lehet indítani a hegesztési folyamatot. A kapcsolóval lehet ki- és bekapcsolni a távirányítót. A LED-ek jelzik a hegesztési folyamat kezdetét, befejezését, valamint a Bluetooth kapcsolatot.   ',
      About_Your_Welder_title: 'Csatlakoztasd gépedhez!',
      About_Your_Welder_desc: 'Az OrbitalChamp egyik legjobb tulajdonsága, hogy bármelyik TIG hegesztőgéppel kompatibilis, így a hegesztő szakember a saját, már jól ismert hegesztőgépét tudja használni',
      About_All_Pipe_title: 'Minden fejméretre',
      About_All_Pipe_desc: 'Termékünk két hegesztőfejjel kapható. Az egyikkel DIN20-tól DIN50-ig lehet hegeszteni, a másikkal pedig DIN50-től DIN100-ig. A mérettartományon belül különböző méterű betéteket használhatunk a különböző csövekhez.',
      About_Next: 'Tovább',
      About_Back: 'Vissza',

      WeldingMachines_Title: 'Melyik hegesztőgéphez csatlakoztatnád?',
      WeldingMachines_Button: 'További AWI hegesztőgépek',

      WeldingsAndForm_Title: 'Orbitális Hegesztéseink',
      WeldingsAndForm_Form_Title: 'Próbálja ki Ön is az Orbitális hegesztőnket ingyen!',
      WeldingsAndForm_Form_Address: 'Helyszín: 1224 XVI. utca 9/a',
      WeldingsAndForm_Form_Button: 'Jelentkezzen kapcsolat felvételre!',

      OrbitalWeldings_Title: 'Orbitális hegesztés',
      OrbitalWeldings_P1: 'Az orbitális hegesztés egy fejlett hegesztési technológia, amelyet elsősorban csövek és hengeres szerkezetek precíziós hegesztésére használnak. Az eljárás precíz, kiváló minőségű varratokat biztosít, és különösen fontos olyan iparágakban, mint a gyógyszeripar, az élelmiszeripar, az olaj- és gázipar, ahol a hegesztési minőség és higiénia kulcsfontosságú.',
      OrbitalWeldings_P2: 'Az orbitális hegesztés legnagyobb előnyei közé tartozik a kiváló hegesztési minőség és a folyamat ismételhetősége. Az automatizált rendszer lehetővé teszi, hogy minden hegesztés ugyanolyan magas színvonalú legyen, ami minimalizálja a hibákat és csökkenti az utólagos ellenőrzés szükségességét. Ezen kívül az orbitális hegesztés gyorsabb, mint a hagyományos módszerek, ami csökkenti a munkavégzés idejét és ezáltal a költségeket is.',
      OrbitalWeldings_P3: 'A beruházás egy orbitális hegesztési rendszerbe hosszú távon megtérülhet, mivel csökkenti a munkaerőköltségeket, javítja a termelés hatékonyságát és növeli a termék minőségét. Az ilyen rendszerek különösen alkalmasak olyan projektekre, ahol nagy mennyiségű csővezeték hegesztése szükséges, és ahol a minőség nem kompromisszum kérdése. Az orbitális hegesztés tehát nemcsak technológiai előnyt nyújt, hanem versenyelőnyt is biztosít azok számára, akik elkötelezettek a kiváló minőség mellett.', 

      Application_title: 'Applikáció',
      Application_Settings_title: 'Beállítások',
      Application_Settings_Start_Welder_title: 'Start hegesztési impulzus',
      Application_Settings_Start_Welder_desc: '0.5 másodperccel nagyobb, mint a gáz előadagolás',
      Application_Settings_Delay_title: 'Indítás késleltetés',
      Application_Settings_Delay_desc: 'Az a várakozási idő, ami alatt az anyag átmelegszik',
      Application_Settings_Rotation_Speed_title: 'Forgatási sebesség',
      Application_Settings_Rotation_Speed_desc: 'A cső kerületét 4 szektorra osztottuk, ezekben a szektorokban különböző sebességekkel tudunk haladni',
      Application_Settings_OverRun_title: 'Túlfutás',
      Application_Settings_OverRun_desc: 'Azt hatérozza meg, hogy a 360 fokon túl hol érjen véget a hegesztés',
      Application_Settings_Stop_Impulse_title: 'Stop impulzus',
      Application_Settings_Stop_Impulse_desc: 'Az Ív elvétel helyét határozza meg',
      Application_Settings_Stop_Welder_Impulse_title: 'Stop hegesztési impulzus',
      Application_Settings_Stop_Welder_Impulse_desc: 'Az ív elvétel idejét határozza meg',
      Application_Settings_Rotation_Direction_title: 'Forgatási irány',
      Application_Settings_Rotation_Direction_desc: 'A hegesztőfej forgásának irányát határozza meg',
      Application_Settings_Meta_title: 'Áttétel',
      Application_Settings_Meta_desc: 'A különböző mérető fejekhez, különböző áttételek taroznak, ezt itt tudjuk beállítani',
      Application_Get_More_Information: 'Tudj meg többet!',
      Application_Inquery_title: 'Lekérdezés',
      Application_Inquery_desc: 'Módunkban áll egy teljes olyan képernyő, ahol le tudjuk kérdezni az eszközre feltöltött adatokat!',
      Application_Save_BT_title: 'Mentés',
      Application_Save_title: 'Mentsd el a hegesztési beállításokat!',
      Application_Save_desc: 'Az applikáció lehetőséget biztosít arra, hogy a hegesztési adatokat lementsük és bármikor újra elő tudjuk hívni, majd fel tudjuk tölteni egy, vagy több eszközre. Ebből következik egy nagyon hasznos tulajdonsága eszközünknek. Nézzünk rá egy példát: A hegesztő mérnök műhely körülmények között felprogramozza az eszközt úgy, hogy a beállított hegesztés végeredménye egy magas minőségű hegesztés legyen. Ezt feltölti az eszközre, majd elmenti a táblázatba, esetleg több eszközre is feltölti. Átadja a hegesztő okosítót egy betanított munkásnak, aki ráhelyezi a meghegesztendő csőre az eszközt, majd a hegesztés elindítása után ugyanazt a minőségi hegesztést végzi el, amit a hegesztőmérnök végzett a műhelyben. Ez a folyamat gyakorlatilag korlátlan számban elvégezhető.',



      Weldings_title: 'Hegesztéseink',
      Weldings_seams: 'Varratok',
      Weldings_root: 'Gyök',
      Weldings_factory: 'Gyártás',

      Contact_title: 'Kapcsolat',
      Contact_name: 'Név',
      Contact_email: 'Eamil',
      Contact_Company_name: 'Cégnév',
      Contact_Phone: 'Telefonszám',
      Contact_message: 'Üzenet',
      Conatct_message_desc: 'Írja ide az üzenetet!',
      Conatct_Send: 'Küldés'
  },
  en: {
    Navbar_Product_title: 'Product',
    Navbar_Application_title: 'Welding Machines',
    Navbar_Weldings_title: 'Our Weldings',
    Navbar_Contact_title: 'Contact',
    Navbar_OrbitalWelding_title: 'Orbital Welding',
    Welcome_Button: 'Get in touch with us!',

    About_Head_title: 'OrbitalChamp',
    About_Head_desc: 'The OrbitalChamp is a device, which can make X-ray proof, high quality and perfect precision welding, even by employees who do not have a welding qualification. ',
    About_High_Quality_Weldings_title: 'Certified welding',

    About_High_Quality_Weldings_desc: 'With the OrbitalChamp high quality welds can be performed. As evidence of this, we have a TÜV certification.',
    About_Track_Setting_title: 'Welding path positioning',
    About_Track_Setting_desc: 'You have the chance to control the welding path during the welding process.',
    About_Electronic_title: 'Electronic controller',
    About_Electronic_desc: 'An electronic controller guides the welding process entirely automatically. This device is programmable with a mobile application.',
    About_UI_title: 'Remote Controller',
    About_UI_desc: 'The OrbitalChamp is a remote-controlled device equipped with three buttons, a switch, and three LEDs. The buttons are used to position the head and initiate the welding process. The switch controls the on/off function of the remote control. The LEDs indicate the start and end of the welding process, as well as the Bluetooth connection.',
    About_Your_Welder_title: 'Connect your own machine!',
    About_Your_Welder_desc: 'One of the key strengths of the OrbitalChamp is it is compatible with every type of TIG welder, the welder can use the welding machine they are familiar with.',
    About_All_Pipe_title: 'Fits all pipe diameters',
    About_All_Pipe_desc: 'Our product is available with two welding heads. One allows welding from DIN20 to DIN50, while the other enables welding from DIN50 to DIN100. Within the size range, various size of inserts can be used for different pipes.',
    About_Next: 'Next',
    About_Back: 'Back',

    WeldingMachines_Title: 'Which welding machine would you like to connect to?',
    WeldingMachines_Button: 'More TIG welding machines',

    WeldingsAndForm_Title: 'Our Orbital Weldings',
    WeldingsAndForm_Form_Title: 'Try our Orbital Welder for free!',
    WeldingsAndForm_Form_Address: 'Location: 1224 XVI. street 9/a',
    WeldingsAndForm_Form_Button: 'Register for contact!',
    
    OrbitalWeldings_Title: 'Orbital Welding',
    OrbitalWeldings_P1: 'Orbital welding is an advanced welding technology primarily used for the precision welding of pipes and cylindrical structures. The process provides precise, high-quality seams and is especially important in industries such as pharmaceuticals, food processing, and oil and gas, where welding quality and hygiene are crucial.', 
    OrbitalWeldings_P2: 'The greatest advantages of orbital welding include excellent welding quality and process repeatability. The automated system ensures that each weld is of the same high standard, minimizing errors and reducing the need for subsequent inspections. Moreover, orbital welding is faster than traditional methods, which reduces the time of operations and thereby the costs.',
    OrbitalWeldings_P3: 'Investing in an orbital welding system can pay off in the long run as it reduces labor costs, improves production efficiency, and enhances product quality. Such systems are particularly suitable for projects requiring extensive pipeline welding where quality is non-negotiable. Thus, orbital welding not only provides a technological advantage but also offers a competitive edge to those committed to excellent quality.',

    Application_title: 'Welding Machines',
    Application_Settings_title: 'Settings',
    Application_Settings_Start_Welder_title: 'Start Welding Impulse',
    Application_Settings_Start_Welder_desc: 'Always set the Start Welding Impulse 0.5 s more then gas pre-dosing',
    Application_Settings_Delay_title: 'Start delay',
    Application_Settings_Delay_desc: 'Set the delay until the material reaches the appropriate temperature',
    Application_Settings_Rotation_Speed_title: 'Rotation speed',
    Application_Settings_Rotation_Speed_desc: 'We can divide the diameter of the pipe into 4 parts. In each part we can set a different speed',
    Application_Settings_OverRun_title: 'Overrun',
    Application_Settings_OverRun_desc: 'Here we can set where the welding will end after 360 degrees.',
    Application_Settings_Stop_Impulse_title: 'Stop Impulse',
    Application_Settings_Stop_Impulse_desc: 'The stop impulse defines the point of the arc removal.',
    Application_Settings_Stop_Welder_Impulse_title: 'Stop Welding Impulse',
    Application_Settings_Stop_Welder_Impulse_desc: 'The stop welding impulse defines the time of the arc removal',
    Application_Settings_Rotation_Direction_title: 'Rotation Direction',
    Application_Settings_Rotation_Direction_desc: 'This defines the rotation direction',
    Application_Settings_Meta_title: 'Transmission',
    Application_Settings_Meta_desc: 'Different head diameters belong to different transmission, and this can be set here',
    Application_Get_More_Information: 'Find out more!',
    Application_Inquery_title: 'Inquiry',
    Application_Inquery_desc: 'There is a full screen display where we can inquire which parameters were uploaded to the device',
    Application_Save_BT_title: 'Save',
    Application_Save_title: 'Saveable welding parameters!',
    Application_Save_desc: "The application provides the chance to save the welding parameters and call them back any time, then upload them  to one or more devices. A very useful strength of our device follows from this. Let's take a look at a useful example: The welding engineer programs the device in the workshop to achieve a high-quality and X-ray proof welding. He/She uploads this to one or more devices, and saves it to the application before handing the smart welding box to the semi-skilled worker, who attaches the orbital welding head to the pipe. This will produce as high-quality welding result as the welder could achieve himself in the workshop. This process is repeatable anytime, anywhere.",

    Weldings_title: 'Our Weldings',
    Weldings_seams: 'Beads',
    Weldings_root: 'Root',
    Weldings_factory: 'Production',

    Contact_title: 'Contact',
    Contact_name: 'Name',
    Contact_email: 'Eamil',
    Contact_Company_name: 'Company name',
    Contact_Phone: 'Phone',
    Contact_message: 'Message',
    Conatct_message_desc: 'Write the message here!',
    Conatct_Send: 'Send'
  },
};

export default translations


