import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../strings/Strings'
import kep1 from '../img/new photo/1.png'
import kep6 from '../img/new photo/6.png'
import kep3 from '../img/new photo/3.png'
import '../Designe/Shop.css'
import { ToastContainer, toast } from "react-toastify";

const products = [
    {
      id: 1,
      title: "OrbitalChamp hegesztőokositó rendszer",
      price: "6999 € + ÁFA",
      image: kep6, // Cseréld ki a megfelelő elérési úttal
    },
    {
      id: 2,
      title: "OrbitalChamp Hegesztőfej DIN50-DIN100 vagy DIN20-DIN50",
      price: "4449 € + ÁFA",
      image: kep1, // Cseréld ki a megfelelő elérési úttal
    },
    {
      id: 3,
      title: "OrbitalChamp Smartbox",
      price: "2499 € + ÁFA",
      image: kep3, // Cseréld ki a megfelelő elérési úttal
    },
  ];




function Shop() {
  const [buttonText, setButtonText] = useState('Vissza a főoldalra');
  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);

    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }

    if (isMobile) {
      setButtonText('⬅'); // Use dynamic text from strings or fallback to Hungarian
    } else {
      setButtonText('Vissza a főoldalra'); // Use dynamic text for desktop
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  
    return (
        <div className="shop-container">

          <a href="/" className="button-back" >{buttonText}</a>
          {/* Termékek   onClick={() => handleProductClick(product)} a divbe*/}
          <h1 className="jakab">Termékeink:</h1>
          <div className="products">
            {products.map((product) => (
              <div 
                className="product-card" 
                key={product.id} 
               >
                <img src={product.image} alt={product.title} className="product-image" />
                <div className="product-info">
                  <p>{product.title}</p>
                  <p className="product-price">Ár: {product.price}</p>
                </div>
              </div>
            ))}
          </div>
    
          {/* További termékek gomb
           <button className="button-more" onClick={showToastMessage}>További termékek</button> */}
        
        </div>
      ); 
  

    
  }
  
  export default Shop;