import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostTop3.css';
import hegesztobacsi from '../../img/form2.jpg'
import iv from '../../img/me_m.jpg'
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import translations from '../../strings/Strings'


function BlogPostTop3() {
  
  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);


  var url = 'https://orbitalchamp.com/Top3OkaHogyMiertHasznaljOrbitalisHegesztot';
  var title = 'Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!';
  var description = 'Az orbitális hegesztés technológiája az utóbbi években egyre nagyobb teret hódított a hegesztési iparban. De miért válasszunk orbitális hegesztőt a hagyományos módszerek helyett? Íme három meggyőző ok, amelyek miatt érdemes a modern technológiát választanod:';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]); 
  
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);








  if(isMobile==false){
  return (
    <div className="blog-container">
   
      <div class="menu">
      <ul>
          <li><a href="/shop" >Bolt</a></li>
          <li><a className="a_active" href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>
          <li><a href="/dummy">Dummy</a></li>

      </ul>

    </div>
      <div className="blog-content">
        <h1 className="bela">Top 3 Oka, Amiért Használj Orbitális Hegesztőt!</h1>
        <img
          className="welding-imagetop"
          src={hegesztobacsi}
          alt="Orbitális hegesztő munka közben"
        />
        <p>

          Az orbitális hegesztés technológiája az utóbbi években egyre nagyobb
          teret hódított a hegesztési iparban. De miért válasszunk orbitális
          hegesztőt a hagyományos módszerek helyett? Íme három meggyőző ok,
          amelyek miatt érdemes a modern technológiát választanod:
        </p>
        <ol>
          <li>
            <strong>Kiemelkedő Pontosság és Ismételhetőség:</strong> Az
            orbitális hegesztés legnagyobb előnye a hihetetlen pontosság és
            ismételhetőség. A hegesztőgép automatikusan beállítható és
            biztosítja, hogy a hegesztési varrat egyenletes és szabályos legyen
            minden egyes hegesztési szakaszon. Ez különösen fontos azokon az
            iparágakon, ahol a hegesztési minőség közvetlenül befolyásolja a
            termék biztonságát és az élettartamát, például a gyógyszeriparban
            vagy az élelmiszeriparban.
          </li>
          <li>
            <strong>Csökkentett Munkaidő és Növelt Hatékonyság:</strong> Az
            orbitális hegesztők gyorsasága és hatékonysága drasztikusan
            csökkentheti a munkahoz szükséges időt. Mivel a gépek részben
            automatizáltak, kevesebb időt és erőforrást kell fordítani a
            munkafolyamatok képzésére. Emellett a csökkentett hegesztési idő azt
            is lehetővé teszi, hogy a projektek gyorsabban készüljenek el, ami
            növeli a termelékenységet és optimalizálja az ipari műveleteket.
          </li>
          <li>
            <strong>Megnövelt Biztonság a Munkahelyen:</strong> A hegesztési
            folyamat automatizálása nemcsak hogy hatékonyabbá, de biztonságosabbá
            is teszi. Az orbitális hegesztők minimalizálják a kézi hegesztéssel
            járó hibákat és baleseteket, mivel a kezelőnek sokkal kevesebb
            fizikai beavatkozásra van szüksége a folyamat alatt. Ez felhasználók
            számára jobb egészségügyi feltételeket teremt, ami tovább csökkenti
            a lehetséges egészségügyi kockázatokat.
          </li>
        </ol>
        <p>
          Ha olyan innovatív technológiára van szükséged, amely forradalmasíthatja
          a hegesztési ipart, pontosság, hatékonyság és biztonság szem előtt
          tartásával, az orbitális hegesztés a legjobb választás. Az alacsonyabb
          hibaarány és gyorsabb elvégzés nemcsak időt spórol meg, de növeli a
          végtermék minőségét is.
        </p>
        Szerző: <br />
        <div className='author_div'>
            <img
              className="author-image"
              src={iv}
              alt="Okleveles Mechatronikai Mérnök"> 
            </img>
            <div className='author-text'>
                <p>               
                <strong>Hopp Márton Péter</strong> <br />
                <hr class="rounded"></hr>
                <span className="author-title">Okleveles Mechatronikai Mérnök</span>
                </p>

            </div>

        </div>

      </div>
      <div className='empty-space'>

      </div>
    </div>
  );
 }else{
  return (
    <div className="blog-container">
      <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="OrbitalChamp.com" />
        </Helmet>

      <div className="blog-content">
        <h1 className="bela">Top 3 Oka, Amiért Használj Orbitális Hegesztőt!</h1>
        <img
          className="welding-imagetop"
          src={hegesztobacsi}
          alt="Orbitális hegesztő munka közben"
        />
        <p>

          Az orbitális hegesztés technológiája az utóbbi években egyre nagyobb
          teret hódított a hegesztési iparban. De miért válasszunk orbitális
          hegesztőt a hagyományos módszerek helyett? Íme három meggyőző ok,
          amelyek miatt érdemes a modern technológiát választanod:
        </p>
        <ol>
          <li>
            <strong>Kiemelkedő Pontosság és Ismételhetőség:</strong> Az
            orbitális hegesztés legnagyobb előnye a hihetetlen pontosság és
            ismételhetőség. A hegesztőgép automatikusan beállítható és
            biztosítja, hogy a hegesztési varrat egyenletes és szabályos legyen
            minden egyes hegesztési szakaszon. Ez különösen fontos azokon az
            iparágakon, ahol a hegesztési minőség közvetlenül befolyásolja a
            termék biztonságát és az élettartamát, például a gyógyszeriparban
            vagy az élelmiszeriparban.
          </li>
          <li>
            <strong>Csökkentett Munkaidő és Növelt Hatékonyság:</strong> Az
            orbitális hegesztők gyorsasága és hatékonysága drasztikusan
            csökkentheti a munkahoz szükséges időt. Mivel a gépek részben
            automatizáltak, kevesebb időt és erőforrást kell fordítani a
            munkafolyamatok képzésére. Emellett a csökkentett hegesztési idő azt
            is lehetővé teszi, hogy a projektek gyorsabban készüljenek el, ami
            növeli a termelékenységet és optimalizálja az ipari műveleteket.
          </li>
          <li>
            <strong>Megnövelt Biztonság a Munkahelyen:</strong> A hegesztési
            folyamat automatizálása nemcsak hogy hatékonyabbá, de biztonságosabbá
            is teszi. Az orbitális hegesztők minimalizálják a kézi hegesztéssel
            járó hibákat és baleseteket, mivel a kezelőnek sokkal kevesebb
            fizikai beavatkozásra van szüksége a folyamat alatt. Ez felhasználók
            számára jobb egészségügyi feltételeket teremt, ami tovább csökkenti
            a lehetséges egészségügyi kockázatokat.
          </li>
        </ol>
        <p>
          Ha olyan innovatív technológiára van szükséged, amely forradalmasíthatja
          a hegesztési ipart, pontosság, hatékonyság és biztonság szem előtt
          tartásával, az orbitális hegesztés a legjobb választás. Az alacsonyabb
          hibaarány és gyorsabb elvégzés nemcsak időt spórol meg, de növeli a
          végtermék minőségét is.
        </p>
        Szerző: <br />
        <div className='author_div'>
            <img
              className="author-image"
              src={iv}
              alt="Okleveles Mechatronikai Mérnök"> 
            </img>
            <div className='author-text'>
                <p>               
                <strong>Hopp Márton Péter</strong> <br />
                <hr class="rounded"></hr>
                <span className="author-title">Okleveles Mechatronikai Mérnök</span>
                </p>

            </div>

        </div>

      </div>
      <div class="menu">
      <ul>
          <li><a href="/shop" >Bolt</a></li>
          <li><a className="a_active" href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>

      </ul>
    </div>
    </div>
  );
 }
}

export default BlogPostTop3;
