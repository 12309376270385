import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostHistory.css';
import hegesztobacsi from '../../img/history.jpg';
import iv from '../../img/t.png';
import { FacebookShareButton, FacebookIcon, FacebookShareCount} from 'react-share';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import translations from '../../strings/Strings'

function BlogPostHistory() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  var url = 'https://orbitalchamp.com/OrbitalisHegesztesTortelnelme';
  var title = 'Az orbitális hegesztés történelme!';
  var description = 'Az orbitális hegesztés egy speciális, automatizált hegesztési technológia, amely elsősorban csövek és csővezetékek összeillesztésére szolgál. A technológia kialakulása az 1960-as évekre tehető, amikor az űrkutatás és az atomenergia-ipar előretörése új, megbízható hegesztési módszereket követelt meg.';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]);  
  
  
  



if(isMobile==false){
  return (

    <div className="blog-container">

    <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="OrbitalChamp.com" />
        </Helmet>

      <div className="menu">
        <ul>
          <li><a href="/shop" replace>Bolt</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a className="a_active" href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>
        </ul>
      </div>

      <div className="blog-content">
        <h1 className="bela">Az Orbitális Hegesztés Történelme</h1>
        <figure>
          <img className="welding-image_his" src={hegesztobacsi} alt="Orbitális hegesztő munka közben" /> 
          <figcaption className="fig_comment">A kép illusztráció</figcaption>
        </figure>

        <p>
          Az orbitális hegesztés egy speciális, automatizált hegesztési technológia, amely elsősorban csövek és csővezetékek összeillesztésére szolgál. A technológia kialakulása az 1960-as évekre tehető, amikor az űrkutatás és az atomenergia-ipar előretörése új, megbízható hegesztési módszereket követelt meg.
        </p>
        
        <h2>A Kezdetek</h2>
        <p>
          Az orbitális hegesztés megszületése szorosan kapcsolódik az Egyesült Államok űrprogramjához. A NASA számára rendkívül fontos volt, hogy a rakéták és űrhajók rendszereiben alkalmazott csővezetékek hegesztése tökéletes legyen, hiszen a legkisebb hiba is katasztrofális következményekkel járhatott. Az első orbitális hegesztőberendezéseket a North American Aviation fejlesztette ki, kifejezetten a vékony falú rozsdamentes acélcsövek precíz hegesztésére.
        </p>

        <h2>Technológiai Fejlődés</h2>
        <p>
          Az 1970-es és 1980-as években a technológia továbbfejlődött és elterjedt más iparágakban is, mint például a vegyipar, a gyógyszeripar és az élelmiszeripar. Ezekben az ágazatokban a tisztaság és a hegesztési varratok minősége kiemelt jelentőségű, ezért az orbitális hegesztés ideális megoldást nyújtott.
        </p>

        <h2>Nyitott és Zárt Hegesztőfejek</h2>
        <p>
          A technológia fejlődése során két fő hegesztőfej-típus alakult ki:
        </p>
        <ul>
          <li><strong>Nyitott hegesztőfejek:</strong> Ezek lehetővé teszik a hegesztési folyamat közvetlen megfigyelését, és rugalmasabban alkalmazhatók különböző csőméretekhez.</li>
          <li><strong>Zárt hegesztőfejek:</strong> A hegesztési területet egy zárt kamrában tartják, ahol inert gáz védi a varratot az oxidációtól, így kiváló minőségű hegesztések érhetők el.</li>
        </ul>

        <h2>Modern Fejlesztések és Az OrbitalChamp</h2>
        <p>
          Napjainkra az orbitális hegesztés széles körben elterjedt és folyamatosan fejlődik. Magyarországon is jelentős előrelépések történtek ezen a területen. Az OrbitalChamp egy magyar fejlesztésű orbitális hegesztőberendezés, amely különlegessége abban rejlik, hogy kompatibilis a legtöbb meglévő hegesztőgéppel. Ez lehetővé teszi a vállalkozások számára, hogy anélkül alkalmazzák az orbitális hegesztés előnyeit, hogy teljesen új rendszert kellene vásárolniuk.
        </p>

        <h2>Az Orbitális Hegesztés Jelentősége</h2>
        <p>
          Az orbitális hegesztés története a technológiai innováció és az ipari igények találkozásának eredménye. A módszer előnyei közé tartozik a kiváló minőségű, ismételhető hegesztési varratok készítése, a munkaidő csökkentése és a termelékenység növelése. Ezek a tényezők teszik nélkülözhetetlenné a technológiát számos iparágban.
        </p>

        <h2>Összegzés</h2>
        <p>
          Az elmúlt évtizedekben az orbitális hegesztés jelentős fejlődésen ment keresztül, és továbbra is kulcsszerepet játszik a modern ipari folyamatokban. A folyamatos innováció és a hazai fejlesztések, mint az OrbitalChamp, biztosítják, hogy ez a technológia a jövőben is meghatározó maradjon.
        </p>

        <div className="author_div">
          <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
          <div className="author-text">
            <p>
              <strong>Horváth Tibor</strong> <br />
              <hr className="rounded" />
              <span className="author-title">OrbitChamp CEO</span>
            </p>
          </div>

        </div>
       
      </div>

      <div className="empty-space"></div>
    </div>
  );
}else{
  return (
    
    <div className="blog-container">
    <Helmet>
        <meta property="og:title" content="Orbitális hegesztés történelme" />
        <meta property="og:description" content="Jakab" />
        <meta property="og:image" content="https://orbitalchamp.com/static/media/history.1049c15f55c5f835378b.jpg" />
        <meta property="og:url" content="https://orbitalchamp.com/Top3OkaHogyMiertHasznaljOrbitalisHegesztot;" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="OrbitalChamp.com" />
      </Helmet>
      <div className="empty-space"></div>

      <div className="blog-content">
        <h1 className="bela">Az Orbitális Hegesztés Történelme</h1>
        <figure>
          <img className="welding-image_his" src={hegesztobacsi} alt="Orbitális hegesztő munka közben" /> 
          <figcaption className="fig_comment">A kép illusztráció</figcaption>
        </figure>

        <p>
          Az orbitális hegesztés egy speciális, automatizált hegesztési technológia, amely elsősorban csövek és csővezetékek összeillesztésére szolgál. A technológia kialakulása az 1960-as évekre tehető, amikor az űrkutatás és az atomenergia-ipar előretörése új, megbízható hegesztési módszereket követelt meg.
        </p>
        
        <h2>A Kezdetek</h2>
        <p>
          Az orbitális hegesztés megszületése szorosan kapcsolódik az Egyesült Államok űrprogramjához. A NASA számára rendkívül fontos volt, hogy a rakéták és űrhajók rendszereiben alkalmazott csővezetékek hegesztése tökéletes legyen, hiszen a legkisebb hiba is katasztrofális következményekkel járhatott. Az első orbitális hegesztőberendezéseket a North American Aviation fejlesztette ki, kifejezetten a vékony falú rozsdamentes acélcsövek precíz hegesztésére.
        </p>

        <h2>Technológiai Fejlődés</h2>
        <p>
          Az 1970-es és 1980-as években a technológia továbbfejlődött és elterjedt más iparágakban is, mint például a vegyipar, a gyógyszeripar és az élelmiszeripar. Ezekben az ágazatokban a tisztaság és a hegesztési varratok minősége kiemelt jelentőségű, ezért az orbitális hegesztés ideális megoldást nyújtott.
        </p>

        <h2>Nyitott és Zárt Hegesztőfejek</h2>
        <p>
          A technológia fejlődése során két fő hegesztőfej-típus alakult ki:
        </p>
        <ul>
          <li><strong>Nyitott hegesztőfejek:</strong> Ezek lehetővé teszik a hegesztési folyamat közvetlen megfigyelését, és rugalmasabban alkalmazhatók különböző csőméretekhez.</li>
          <li><strong>Zárt hegesztőfejek:</strong> A hegesztési területet egy zárt kamrában tartják, ahol inert gáz védi a varratot az oxidációtól, így kiváló minőségű hegesztések érhetők el.</li>
        </ul>

        <h2>Modern Fejlesztések és Az OrbitalChamp</h2>
        <p>
          Napjainkra az orbitális hegesztés széles körben elterjedt és folyamatosan fejlődik. Magyarországon is jelentős előrelépések történtek ezen a területen. Az OrbitalChamp egy magyar fejlesztésű orbitális hegesztőberendezés, amely különlegessége abban rejlik, hogy kompatibilis a legtöbb meglévő hegesztőgéppel. Ez lehetővé teszi a vállalkozások számára, hogy anélkül alkalmazzák az orbitális hegesztés előnyeit, hogy teljesen új rendszert kellene vásárolniuk.
        </p>

        <h2>Az Orbitális Hegesztés Jelentősége</h2>
        <p>
          Az orbitális hegesztés története a technológiai innováció és az ipari igények találkozásának eredménye. A módszer előnyei közé tartozik a kiváló minőségű, ismételhető hegesztési varratok készítése, a munkaidő csökkentése és a termelékenység növelése. Ezek a tényezők teszik nélkülözhetetlenné a technológiát számos iparágban.
        </p>

        <h2>Összegzés</h2>
        <p>
          Az elmúlt évtizedekben az orbitális hegesztés jelentős fejlődésen ment keresztül, és továbbra is kulcsszerepet játszik a modern ipari folyamatokban. A folyamatos innováció és a hazai fejlesztések, mint az OrbitalChamp, biztosítják, hogy ez a technológia a jövőben is meghatározó maradjon.
        </p>

        <div className="author_div">
          <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
          <div className="author-text">
            <p>
              <strong>Horváth Tibor</strong> <br />
              <hr className="rounded" />
              <span className="author-title">OrbitChamp CEO</span>
            </p>
          </div>

        </div>
       
      </div>

      
      <div className="menu">
        <ul>
          <li><a href="/shop" replace>Bolt</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Top 3 oka, hogy miért használj ORBITÁLIS HEGESZTŐT!</a></li>
          <li><a className="a_active" href="/OrbitalisHegesztesTortelnelme">Orbitális hegesztés történelme</a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto">Ezért leszel egyre értékesebb, mint hegesztő</a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">A magyar orbitális hegesztő: Az OrbitalChamp</a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">Miért Nem Veszi El a Hegesztők Munkáját az Orbitális Hegesztés?</a></li>
        </ul>
      </div>
    </div>
  );




}
}



export default BlogPostHistory;
