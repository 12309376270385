import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../strings/Strings'
import kep3 from '../img/new photo/3.png'
import '../Designe/ShopItem.css'

function ShopItem() {

    return (
        <div className="shop-item-container">
            <h1>Termékünk</h1>
            <div className='img-container'>
                <img src={kep3} alt="OrbitalChamp welding system" />
            </div>
            <div className='text-container'>
                <h2>OrbitalChamp hegesztőokosító rendszer</h2>
                <p>
                    Az OrbitalChamp hegesztőokosító teljes rendszere is megvásárolható.
                    Ez a csomag tartalmazza a Smartboxot és egy választható méretű orbitális jetet.
                </p>
                <h3>Tartalma:</h3>
                <ul>
                    <li>Smartbox</li>
                    <li>Orbitális hegesztőfej</li>
                    <li>4 db betét</li>
                    <li>Hegesztő csatlakozó</li>
                    <li>Munka kábel</li>
                    <li>Hegesztő fej</li>
                </ul>
                <p className='price_a'>Ár: 2 750 000 Ft</p>
            </div>
        </div>
      );

}

export default ShopItem;