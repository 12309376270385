import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Designe/WeldingGalleryAndForm.css';

import felulet1 from '../img/webheg/felulet1.jpeg';
import felulet2 from '../img/webheg/felulet2.jpeg';
import gyok1 from '../img/webheg/gyok1.jpeg';
import gyok2 from '../img/webheg/gyok2.jpeg';
import gyar1 from '../img/webheg/gyar1.jpeg';
import gyar2 from '../img/webheg/gyar2.jpeg';
import next from '../img/next.png';
import Form from '../img/form2.jpg';
import Orbital1 from '../img/Weldings/Orbital1.jpg';
import Orbital2 from '../img/Weldings/Orbital2.jpg';
import Orbital3 from '../img/Weldings/Orbital3.jpg';
import Orbital4 from '../img/Weldings/Orbital4.jpg';
import Orbital5 from '../img/Weldings/Orbital5.jpg';


import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

const WeldingGalleryAndForm = () => {
    const images = [
        Orbital1,
        Orbital2,
        Orbital3,
        Orbital4,
        Orbital5,
        felulet1,
        felulet2,
        gyar1,
        gyok1,
        gyok2,
        gyar2,
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [feedback, setFeedback] = useState({ message: '', type: '' });

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleImageClick = (image) => {
        setLightboxImage(image);
        setIsLightboxOpen(true);
    };

    const handleCloseLightbox = () => {
        setIsLightboxOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios
            .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
                to: 'info@orbitalchamp.com', // Cél email cím
                subject: 'Kapcsolatfelvétel az Orbitalchamp.com-ról!',
                message: `
                    Név: ${name}
                    Email: ${email}
                    Cégnév: ${company}
                    Telefonszám: ${phoneNumber}
                `,
            })
            .then((response) => {
                console.log('Sikeres email küldés:', response.data.message);
                setFeedback({ message: 'Az üzenet sikeresen elküldve!', type: 'success' });
            })
            .catch((error) => {
                console.error('Hiba az email küldésekor:', error);
                setFeedback({ message: 'Hiba történt az üzenet küldése közben!', type: 'error' });
            });
    };

    const language = useSelector((state) => state.language);
    const [strings,setStrings] = useState(translations.hun)
    useEffect(() => {
      // Itt lehet kezelni a nyelv állapotváltozásait
      console.log('Nyelv megváltozott:', language);
      if(language=== 'en'){
        setStrings(translations.en)
      }else{
        setStrings(translations.hun)
      }
    }, [language]);

    if(window.innerWidth>762){

    return (
        <div className="welding-page-container" id="Csőhegesztés">
            <h1>{strings.WeldingsAndForm_Title}</h1>
            <div className="gallery-container">
                <button className="prev-btn" onClick={handlePrevClick}>
                    <img src={next} alt="Előzőkép a csőhegesztésről" />
                </button>
                <div className="gallery-grid">
                    {images.slice(currentIndex, currentIndex + 4).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Magas minőségű Orbitális hegsztés ${index}`}
                            className="gallery-image"
                            onClick={() => handleImageClick(image)}
                        />
                    ))}
                </div>
                <button className="next-btn" onClick={handleNextClick}>
                    <img src={next} alt="Következő kép a csőhegesztésről" />
                </button>
            </div>

            <div className="form-section">
                <div className="form-image">
                    <img src={Form} alt="OrbitalChamp a saját AWI hegesztődből" />
                </div>
                <div className="form-container">
                    <h2>{strings.WeldingsAndForm_Form_Title}</h2>
                    <p>{strings.WeldingsAndForm_Form_Address}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder={strings.Contact_name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="tel"
                                placeholder={strings.Contact_Phone}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder={strings.Contact_Company_name}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                            <input
                                type="email"
                                placeholder={strings.Contact_email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" aria-label="Jelentkezzen kapcsolat felvételre - Csőhegesztés és Orbitális hegesztés" >{strings.WeldingsAndForm_Form_Button}</button>
                    </form>
                    {feedback.message && (
                        <div className={`feedback_${feedback.type === 'success' ? 'success' : 'error'}`}>
                            <p>{feedback.message}</p>
                        </div>
                    )}
                </div>
            </div>

            {isLightboxOpen && (
                <div className="lightbox" onClick={handleCloseLightbox}>
                    <span className="lightbox-close">&times;</span>
                    <img src={lightboxImage} alt="Sikeres Email küldés az Orbitális hegsztés elérésenek érdekében" className="lightbox-image" />
                </div>
            )}
        </div>
    );
  }else{
    return (
        <div className="welding-page-container" id="Csőhegesztés">
            <h1>{strings.WeldingsAndForm_Title}</h1>
            <div className="gallery-container">
                <button className="prev-btn" onClick={handlePrevClick}>
                    <img src={next} alt="Előzőkép a csőhegesztésről" />
                </button>
                <div className="gallery-grid">
                    {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Magas minőségű Orbitális hegsztés ${index}`}
                            className="gallery-image"
                            onClick={() => handleImageClick(image)}
                        />
                    ))}
                </div>
                <button className="next-btn" onClick={handleNextClick}>
                    <img src={next} alt="Következő kép a csőhegesztésről" />
                </button>
            </div>

            <div className="form-section">

                <div className="form-container">
                    <h2>{strings.WeldingsAndForm_Form_Title}</h2>
                    <p>{strings.WeldingsAndForm_Form_Address}</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder={strings.Contact_name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="tel"
                                placeholder={strings.Contact_Phone}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder={strings.Contact_Company_name}
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                            <input
                                type="email"
                                placeholder={strings.Contact_email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" aria-label="Jelentkezzen kapcsolat felvételre - Csőhegesztés és Orbitális hegesztés" >{strings.WeldingsAndForm_Form_Button}</button>
                    </form>
                    {feedback.message && (
                        <div className={`feedback_${feedback.type === 'success' ? 'success' : 'error'}`}>
                            <p>{feedback.message}</p>
                        </div>
                    )}
                </div>
                <div className="form-image">
                    <img src={Form} alt="OrbitalChamp a saját AWI hegesztődből" />
                </div>
            </div>

            {isLightboxOpen && (
                <div className="lightbox" onClick={handleCloseLightbox}>
                    <span className="lightbox-close">&times;</span>
                    <img src={lightboxImage} alt="Sikeres Email küldés az Orbitális hegsztés elérésenek érdekében" className="lightbox-image" />
                </div>
            )}
        </div>
    );
  }
};

export default WeldingGalleryAndForm;
