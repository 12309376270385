
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navigation';
import Application from './components/Application';
import Weldings from './components/Weldings';
import WeldingMachines from './components/WeldingMachines';
import WeldingGalleryAndForm from './components/WeldingsAndForm';
import WeldingSection from './components/OrbitalWelding';

import HamburgerMenu from './components/HamburgerMenu';

import { Provider } from 'react-redux';
import store from './redux/store';


function App() {

  console.log(window.innerWidth + "x" + window.innerHeight)

  if(window.innerWidth>762){
  return (
    <Provider store={store}>
    <Router>
      <div>
      <Navbar/>
      <Home/>
      <About/>
      <WeldingMachines/>
      <WeldingGalleryAndForm/>
      <WeldingSection/>
      <Contact/>
      </div>
    </Router>
    </Provider>
  );
  }else{
  return (
    <Provider store={store}>
    <Router>
      <div>
      <HamburgerMenu/>
      <Home/>
      <About/>
      <Application/>
      <Weldings/>
      <Contact/>      
      </div>
    </Router>
    </Provider>
     );
  }
}

export default App;
