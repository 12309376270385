import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Designe/HamburgerMenu.css';
import Logo from '../img/Logo.svg';
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';

import translations from '../strings/Strings'

const HamburgerMenu = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [strings,setStrings] = useState(translations.hun)

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleMenuItemClick = (sectionId) => {
    setMenuOpen(false);
    scrollToSection(sectionId);
  };

  const EN =() => {
    dispatch(setLanguage('en'))
    setStrings(translations.en)
  } 

  const HUN =() => {
    dispatch(setLanguage('hun'))
    setStrings(translations.hun)
  } 


  return (
    
    <div className='menu-conatiner'>
    {/* Logo bal oldalon */}
    <div className="logo-container">
      <img src={Logo} alt="Logo" onClick={() => scrollToSection('home')} className="menu-logo" />
        <div className="navbar-button-lang-container">
          <button className='navbar-button-lang' onClick={() => EN()}>EN</button>
          <button className='navbar-button-lang' onClick={() => HUN()}>HUN</button>
        </div>
    </div>



    {/* Hamburger menü */}
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      {/* Itt vannak a menüpontok */}
      <a className="menu-item" onClick={() => (handleMenuItemClick('about'))}>
        {strings.Navbar_Product_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('app'))}>
      {strings.Navbar_Application_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('weldings'))}>
      {strings.Navbar_Weldings_title}
      </a>
      <a className="menu-item"  onClick={() => (handleMenuItemClick('contact'))}>
        {strings.Navbar_Contact_title}
      </a>
    </Menu>
  </div>
  );
};

export default HamburgerMenu;
