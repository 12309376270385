import React, { useEffect, useState } from 'react';
import '../Designe/Home.css'
//import Welcome from '../img/1-min.png'
import WelcomeImage from '../img/new photo/5.png'
import WelcomeVideo from '../img/video/WelcomeVideo.mp4'
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'

const Home =() => {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  return (
    <div className="home-page" id="home" style={{ height: '100vh', width: '100%' }}>
      {isMobile ? (
        <>
          <img src={WelcomeImage} alt="Orbitális hegesztő" className="center-image" />
          <div className="text-overlay">
            <div className="text-overlay-tb">
              <h2 className="Title">OrbitalChamp</h2>
              <button onClick={() => scrollToSection('contact')} className="cta-button" aria-label="Lépjen kapcsolatba velünk">
                {strings.Welcome_Button}
              </button>
            </div>
          </div>
        </>
      ) : (
        <video className="center-video" autoPlay loop muted poster={WelcomeImage}>
          <source src={WelcomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Home;
