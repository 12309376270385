import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Designe/HamburgerMenu.css';
import Logo from '../img/Logo.svg';
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';

import translations from '../strings/Strings'

const HamburgerMenu = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [strings,setStrings] = useState(translations.hun)

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleMenuItemClick = (sectionId) => {
    setMenuOpen(false);
    scrollToSection(sectionId);
  };

  const EN =() => {
    dispatch(setLanguage('en'))
    setStrings(translations.en)
  } 

  const HUN =() => {
    dispatch(setLanguage('hun'))
    setStrings(translations.hun)
  } 

  // Add useEffect to handle scrolling based on the URL hash
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Get the hash without the #
      if (hash) {
        scrollToSection(hash); // Scroll to the section indicated by the hash
      }
    };

    // Initial check when the component mounts
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []); // Only run this effect once on component mount

  return (
    
    <div className='menu-conatiner'>
    {/* Logo bal oldalon */}
    <div className="logo-container">
      <img src={Logo} alt="Logo" to='/home' onClick={() => scrollToSection('home')} className="menu-logo" />
        <div className="navbar-button-lang-container">
          <button className='navbar-button-lang' onClick={() => EN()}>EN</button>
          <button className='navbar-button-lang' onClick={() => HUN()}>HUN</button>
        </div>
    </div>



    {/* Hamburger menü */}
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      {/* Itt vannak a menüpontok */}
      <a className="menu-item" href='/#about' onClick={() => (handleMenuItemClick('about'))}>
        {strings.Navbar_Product_title}
      </a>
      <a className="menu-item" href='/#AWI Hegesztőgépek' onClick={() => (handleMenuItemClick('AWI Hegesztőgépek'))}>
      {strings.Navbar_Application_title}
      </a>
      <a className="menu-item" href='/#Csőhegesztés' onClick={() => (handleMenuItemClick('Csőhegesztés'))}>
      {strings.Navbar_Weldings_title}
      </a>
      <a className="menu-item" href='/#Orbitális Hegesztés'  onClick={() => (handleMenuItemClick('Orbitális Hegesztés'))}>
      {strings.Navbar_OrbitalWelding_title}
      </a>
      <a className="menu-item" href='/#contact' onClick={() => (handleMenuItemClick('contact'))}>
        {strings.Navbar_Contact_title}
      </a>
      <a className="menu-item"  href="/shop">Bolt</a>
      <a className="menu-item"  href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Blog</a>

    </Menu>
  </div>
  );
};

export default HamburgerMenu;
