import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Designe/Navbar.css';
import Logo from '../img/Logo.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';

import translations from '../strings/Strings'

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [strings,setStrings] = useState(translations.hun)

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const EN =() => {
    dispatch(setLanguage('en'))
    setStrings(translations.en)
  } 

  const HUN =() => {
    dispatch(setLanguage('hun'))
    setStrings(translations.hun)
  } 

  useEffect(() => {

    if(language=== 'en'){
      setStrings(translations.en)
    }else{
      setStrings(translations.hun)
    }
    

    // Frissítjük a windowWidth állapotot, amikor az ablak mérete megváltozik
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Töröljük az eseménykezelőt a komponens megszűnésekor
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={Logo} style={{height: window.innerHeight/8}} onClick={() => scrollToSection('home')} alt="OrbitalChamp" />
      </div>
      <ul className="navbar-menu">
        {/* A továbbiakban az összes 'a' elemet Link komponensre cseréljük */}
        <Link to="/Orbitális hegesztő" onClick={() => scrollToSection('Orbitális hegesztő')} className="nav-link">
          <li className="navbar-item">
            <a href="#Orbitális hegesztő" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_Product_title}
            </a>
          </li>
        </Link>
        <Link to="/AWI Hegesztőgépek" onClick={() => scrollToSection('AWI Hegesztőgépek')} className="nav-link">
          <li className="navbar-item">
            <a href="/AWI Hegesztőgépek" className="navbar-link" style={{fontSize: windowWidth/70}}>
            {strings.Navbar_Application_title}
            </a>
          </li>
        </Link>
        <Link to="/Csőhegesztés" onClick={() => scrollToSection('Csőhegesztés')}className="nav-link">
          <li className="navbar-item">
            <a href="/Csőhegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_Weldings_title}
            </a>
          </li>
        </Link>
        <Link to="/Orbitális Hegesztés" onClick={() => scrollToSection('Orbitális Hegesztés')}className="nav-link">
          <li className="navbar-item">
            <a href="/Orbitális Hegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_OrbitalWelding_title}
            </a>
          </li>
        </Link>
        <li className="navbar-item">
          <button className='navbar-button' onClick={() => scrollToSection('contact')} style={{fontSize: windowWidth/60, padding: windowWidth/120}}>{strings.Navbar_Contact_title}</button>
        </li>
        <li className="navbar-item">
        <button className='navbar-button-lang' onClick={() => EN()}>EN</button>
        <button className='navbar-button-lang' onClick={() => HUN()}>HUN</button>
        </li>
      </ul>

      {/* Itt jelenítjük meg a hamburger ikont csak mobil nézetben */}
      {windowWidth <= 767 && (
        <div className="mobile-menu-icon" onClick={() => setMenuOpen(!isMenuOpen)}>
          <div className={`bar ${isMenuOpen ? 'change' : ''}`} />
          <div className={`bar ${isMenuOpen ? 'change' : ''}`} />
          <div className={`bar ${isMenuOpen ? 'change' : ''}`} />
        </div>
      )}

      {/* A Menu komponens jelenik meg, amikor a hamburger ikonra kattintunk, és csak mobil nézetben */}
      {windowWidth <= 767 && (
        <Menu isOpen={isMenuOpen} onStateChange={handleMenuStateChange}>
          {/* Itt a lenyíló menü elemei */}
          <Link to="/about" onClick={() => closeMenu()} className="menu-item">
            Termékünk
          </Link>
          <Link to="/app" onClick={() => closeMenu()} className="menu-item">
            Applikáció
          </Link>
          <Link to="/weldings" onClick={() => closeMenu()} className="menu-item">
            Hegesztéseink
          </Link>
          <div className="menu-item">
            <button onClick={() => scrollToSection('contact')}>Kapcsolat</button>
          </div>
        </Menu>
      )}
    </nav>
  );
};

export default Navbar;
